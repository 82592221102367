import React, {useEffect, useReducer} from 'react';

import {getInitialData} from '../api/api';
import Checkout from '../util/Checkout';
import {getReferrer} from '../util';


const initializeState = (data = {}, loading = true) => {
  const hasPermission = (p) => data.permissions && data.permissions.indexOf(p) > -1;

  return {
    loading,
    currentUser: data.currentUser,
    coachSlug: data.coachSlug,
    pageBanner: data.pageBanner,
    courses: data.courses,
    hasSubscription: data.hasSubscription,
    features: data.features,
    catalog: data.catalog,
    testimonials: data.testimonials || [],
    currency: data.currency || {symbol: '$', code: 'USD'},
    csrfToken: data.csrfToken,
    referrer: getReferrer(),
    pendingAccount: data.pendingAccount,
    hasPermission,
    onCheckout: () => {},
    onMasterclassCheckout: () => {},
    notifications: [],
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'initialize': {
      return initializeState(action.payload, false);
    }
    case 'dismissChecklist': {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          data: {
            ...state.currentUser.data,
            has_dismissed_checklist: true,
          },
        },
      };
    }
    case 'updateUser': {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          displayName: action.payload.displayName,
          email: action.payload.email,
          first_name: action.payload.firstName,
          is_anonymous: action.payload.isAnonymous,
          last_name: action.payload.lastName,
          profile_img: action.payload.profileImage,
          role: action.payload.role,
          username: action.payload.username,
          data: {
            ...state.currentUser.data,
            bio: action.payload.bio,
          },
        },
      };
    }
    case 'updateUserProfile': {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          profile: {
            ...state.currentUser.profile,
            ...action.payload,
          },
        },
      };
    }
    case 'seenOnboardingVideo': {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          data: {
            ...state.currentUser.data,
            has_seen_onboarding_video: true,
          },
        },
      };
    }
    case 'finishedUniversityOnboarding': {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          data: {
            ...state.currentUser.data,
            finished_university_onboarding: true,
          },
        },
      };
    }
    case 'updateUniversityProfile': {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          universityProfile: action.payload,
        },
      };
    }
    case 'clearReferrer': {
      return {
        ...state,
        referrer: null,
      };
    }
    case 'initializeCheckout': {
      const checkout = new Checkout(state.catalog);
      checkout.initialize();
      return {
        ...state,
        onCheckout: checkout.openCheckout,
        onMasterclassCheckout: checkout.openMasterclassCheckout,
        getStripe: checkout.getStripe,
      };
    }
    case 'notification': {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          action.payload,
        ],
      };
    }
    case 'updateSubscription': {
      return {
        ...state,
        hasSubscription: action.payload.hasSubscription,
      };
    }
    default:
      throw new Error(); // TODO: Catch
  }
};

export const AppProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, {}, initializeState);

  useEffect(() => {
    // Check for content and iv query params coming from Pramp SSO.
    // Pass to getInitialData in order to surface pendingAccount
    const urlParams = new URLSearchParams(window.location.search);
    const content = urlParams.get('content');
    const iv = urlParams.get('iv');

    getInitialData(content, iv).then((payload) => {
      dispatch({type: 'initialize', payload});
      dispatch({type: 'initializeCheckout'});
    });
  }, []);

  return (
    <AppContext.Provider value={{...state, dispatch}}>
      {children}
    </AppContext.Provider>
  );
};
export const AppContext = React.createContext({});
export const AppConsumer = AppContext.Consumer;
